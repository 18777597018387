import React from "react"; 
import "./AdComponent.css";

const AdComponent = ({ layout = "horizontal" }) => {
  const isVertical = layout === "vertical";

  return (
    <div className={`ad-container ${isVertical ? "vertical" : "horizontal"}`}>
  <div className={`ad-content ${isVertical ? "vertical" : "horizontal"}`}>

        <div className="ad-text-content">
        <h1 className="ad-title">
  <span className="emoji">{isVertical ? "👑" : "👑"}</span>
  <span className="text">
    {isVertical
      ? " Fun Way to Learn Code, Solve Exciting Challenges!"
      : " 4,000+ Coding Challenges Await! Level Up Your Skills"}
  </span>
</h1>




          {/* Lottie animation for vertical layout */}
          {isVertical && (
            <div className="lottie-wrapper">
              <iframe
                src="https://lottie.host/embed/169b0dad-28c9-4616-b9de-631c00496444/ec1SWxla70.lottie"
                className="lottie-animation"
                title="Vertical Lottie Animation"
              ></iframe>
            
            </div>
             
          )}

          <div className="ad-detail">
            <ul>
              {isVertical ? (
                <>
                  <li>🛠️ Master coding by solving hundreds of challenges every day</li>
                  <li>🌍 Practice on problems from different domains and languages</li>
                  <li>💡 Get better at logical thinking and problem-solving with every challenge</li>
                </>
              ) : (
                <>
                  <li>🏆 Don't get stuck—learn coding the best way by practicing coding challenges!</li>
                  <li>📝 Access thousands of coding challenges across various programming languages</li>
                  <li>🧠 Sharpen your problem-solving skills with real-world scenarios</li>
                </>
              )}
            </ul>
          </div>

          {/* Buttons placed below the text content */}
          <div className="ad-buttons">
  <a
    href="https://challenges.crunchcoding.com"
    target="_blank"
    rel="noopener noreferrer"
    className="ad-button"
  >
    Explore Challenges
  </a>
</div>

        </div>

        {/* Default Lottie animation for horizontal layout */}
        {!isVertical && (
          <div className="lottie-wrapper">
            <iframe
              src="https://lottie.host/embed/2a3a06e4-ae9e-4560-9b0c-f118ddae565b/HJFJ7Agpva.lottie"
              className="lottie-animation"
              title="Horizontal Lottie Animation"
            ></iframe>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdComponent;
